import React, { useState, useEffect, useContext } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import "../styles/pages/library.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NewButton from "../components/NewButton"
import { useDebounce, useArticles } from "../services/hooks"
import CartContext from "../context/cartContext"
import ArticleItem from "../components/ArticleItem"
import { getBlogUrl } from "../services/utils"

const colorTags = {
  glasses: "orange",
  sunglasses: "green",
  contacts: "purple",
}

const Library = ({ data }) => {
  const articlesList = data.allContentfulBlog.edges.map(({ node }) => node)

  // const sortValues = [
  //   { label: "Sort By", value: "manual" },
  //   { label: "A-Z", value: "title-L" },
  //   { label: "Z-A", value: "title-H" },
  // ]

  const { setHeaderColor } = useContext(CartContext)
  const [currentArticles, setCurrentArticles] = useState([])
  const [featuredArticle, setFeaturedArticle] = useState(articlesList[0])
  const [articlesPerLoad, setArticlesPerLoad] = useState(9)
  const [filter, setFilter] = useState({
    sort: "",
    tag: "",
  })
  const [isFiltered, setIsFiltered] = useState(false)

  const debouncedFilter = useDebounce(filter, 500)

  const filteredArticles = useArticles(articlesList, debouncedFilter)

  const handleClickTag = tag => {
    setIsFiltered(true)

    if (!tag || filter.tag === tag) {
      setIsFiltered(false)
      return setFilter(prev => ({ ...prev, tag: "" }))
    }

    setIsFiltered(true)
    setFilter(prev => ({ ...prev, tag }))
  }

  const handleClickSelect = e => {
    const sort = e?.target?.value
    setFilter(prev => ({ ...prev, sort }))
  }

  const uploadArticles = start => {
    const currentLength = start === "start" ? 0 : currentArticles.length

    const newArticles = filteredArticles.slice(
      currentLength,
      currentLength + articlesPerLoad
    )

    if (start === "start") {
      setCurrentArticles(newArticles)
    } else {
      setCurrentArticles(prevArticles => [...prevArticles, ...newArticles])
    }

    return newArticles
  }

  useEffect(() => {
    uploadArticles("start")
  }, [debouncedFilter])

  useEffect(() => {
    if (!featuredArticle) return

    if (typeof window !== "undefined"){
      window.scrollTo(0, 0);
    }

    setHeaderColor(colorTags[featuredArticle?.tags?.[0]])

    return () => {
      setHeaderColor("black")
    }
  }, [])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/blog",
      "@type": "webpage",
      "name": "Hubble Contacts Eye Care Blog & Vision Resources Library",
      "description": "Learn everything you need to know about eye care and vision health with the Hubble blog. Get answers to commonly asked questions about contact lenses and glasses with our eye health resources.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble Contacts"
      }
    }

  ]



  return (
    <Layout>
      <SEO
        title="Hubble Contacts Eye Care Blog & Vision Resources Library"
        description="Learn everything you need to know about eye care and vision health with the Hubble blog. Get answers to commonly asked questions about contact lenses and glasses with our eye health resources."
        schemaMarkup={schemaMarkup}
      />
      <div className="library-content">
        {featuredArticle && (
          <div
            className="featured-article-container"
            data-color={colorTags[featuredArticle?.tags?.[0]]}
          >
            <div className="grid-section">
              <h1 className="heading text h1">Hubble Library</h1>
              <div className="featured-article">
                <Link to={`/blog/${featuredArticle.url}`} className="image-block">
                  <GatsbyImage image={featuredArticle.mainImage.gatsbyImageData} alt="" />
                </Link>
                <div className="text-block">
                    <div className="tags-container">
                        <p className="tagname text h8" data-color={"Glasses"}>
                          Featured
                        </p>
                    </div>
                  <div className="library-article-title">
                    <Link to={`/blog/${featuredArticle.url}`} className="title text h4">{featuredArticle.title}</Link>
                  </div>
                  <div className="library-article-description">
                    <p className="description text neue">
                      {featuredArticle.excerpt}
                    </p>
                  </div>
                  <div className="library-article-link">
                    <NewButton
                      label="Read Article"
                      to={`/blog/${featuredArticle.url}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="articles-container">
          <div className="grid-section">
            <h3 className="heading text h3">All Articles</h3>
            <div className="filters-container">
              <div className="tags-wrapper">
                <div className="tags-container">
                  <button
                    className="tagname text h8 main"
                    onClick={() => handleClickTag()}
                  >
                    All
                  </button>
                  {Object.entries(colorTags).map(([tag, color], index) => (
                    <button
                      className={`tagname text h8 ${
                        filter.tag === tag ? "active" : ""
                      }`}
                      data-color={color}
                      key={index}
                      onClick={() => handleClickTag(tag)}
                    >
                      {tag}
                    </button>
                  ))}
                </div>
              </div>
              <div className="sort-container">
                <p className="amount text neutral">
                  {`Showing ${currentArticles.length} Item${
                    currentArticles.length > 1 ? "s" : ""
                  }`}
                </p>
              </div>
            </div>
            <div className="articles-list">
              {currentArticles.map(({ mainImage, tags, title, url, createdAt }, index) => (
                <ArticleItem
                  image={mainImage}
                  tags={tags}
                  title={title}
                  url={`/blog/${url}`}
                  publishedAt={createdAt}
                  key={index}
                />
              ))}
            </div>
            {currentArticles.length !== filteredArticles.length && (
              <div className="load-more-container">
                <NewButton label="Load More" onClick={uploadArticles} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Library

export const query = graphql`
{
  allContentfulBlog {
    edges {
      node {
        title
        excerpt
        tags
        createdAt
        seoTitle
        seoDescription
        url
        mainImage {
          gatsbyImageData(placeholder: BLURRED, quality: 80)
        }
      }
    }
  }
}
`
