import React from "react"
import { Link } from "gatsby"

import "./style.scss"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

const ArticleItem = ({ image, tags, title, url }) => {
  const colorTags = {
    glasses: "orange",
    sunglasses: "green",
    contacts: "purple",
  }

  return (
    <Link to={url} className="article-item">

      <div className="article-item-wrapper">
        <div className="image-container">
          <GatsbyImage style={{height: "100%"}} objectFit="cover" image={image.gatsbyImageData}/>
        </div>

        <div className="tags-container">
          {tags?.map((tag, index) => (
            <a
              className="tagname text h8"
              data-color={colorTags[tag]}
              key={index}
            >
              {tag}
            </a>
          ))}
        </div>

        <div className="article-text-preview">
          <h6 className="title text h6">{title}</h6>
        </div>
      </div>

      <div className="article-item-link-wrapper">
        <a className="to-article">
          <span className="text bulky">Read Article</span>
          <StaticImage src={"../../images/ArrowRight.svg"} alt="" />
        </a>
      </div>
    </Link>
  )
}

export default ArticleItem
